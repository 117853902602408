<template>
  <div class="internship">
    <el-table :data="tableData" v-loading="loading" style="width: 100%;">
      <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column prop="gender" label="性别" width="60"></el-table-column>
        <el-table-column prop="cell_phone" label="手机号" width="120"></el-table-column>
        <el-table-column label="反馈" width="80">
          <template slot-scope="scope">
            <span @click="popup(scope.row)" class="feedback">{{ scope.row.is_feedback === 0 ? '未提交' : '反馈表' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="school" label="学校" show-overflow-tooltip></el-table-column>
        <el-table-column prop="college" label="班级" show-overflow-tooltip></el-table-column>
        <el-table-column prop="job_title" label="实习岗位" show-overflow-tooltip></el-table-column>
        <el-table-column prop="created" label="报名时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="internship_time" label="实习时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="chain_name" label="公司"></el-table-column>
    </el-table>

    <!-- 反馈弹窗 -->
    <el-dialog title="反馈表" :modal="false" :visible.sync="isDialog">
      <el-form ref="feedbackData" :model="feedbackData">
        <el-row><el-col :span="24">
          <el-row :gutter="20"> 
            <el-form-item label="1、创新能力" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio01" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="2、实践动手能力" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio02" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="3、人际沟通能力" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio03" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="4、心理素质及抗压能力" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio04" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="5、合作与协调能力" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio05" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row :gutter="20">
            <el-form-item label="6、专业水平" class="custom-label-form-item">
              <el-radio-group v-model="feedbackData.radio06" style="width: 100%;">
                <el-col :span="4">
                  <el-radio :label="1">1分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="2">2分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="3">3分</el-radio>
                </el-col>
                <el-col :span="4">
                  <el-radio :label="4">4分</el-radio>
                </el-col>
                <el-col :span="8">
                  <el-radio :label="5">5分</el-radio>
                </el-col>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="7、综合评价" class="custom-label-form-item">
              <el-col :span="24">
                <el-input style="width: 80%;" type="textarea" v-model="feedbackData.textarea"></el-input>
              </el-col>
            </el-form-item>
          </el-row>
        </el-col></el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getClassInternship } from '@/request/api'

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      isDialog: false,
      feedbackData: {
        radio01: null,
        radio02: null,
        radio03: null,
        radio04: null,
        radio05: null,
        radio06: null,
        textarea: ''
      }
    }
  },
  methods: {
    async init() {
      if (this.loading) return

      this.loading = true
      const result = await getClassInternship()
      this.loading = false
      if (result.data.code === "1000") {
        this.tableData = result.data.data.map((item) => {
          item.internship_time = item.start_internship + ' 至 ' + item.end_internship
          return item
        })
      } else {
        this.$message.error(result.data.msg);
      }
    },
    popup(row) {
      if (row.is_feedback === 1) {
        this.feedbackData = JSON.parse(row.feedback)
        this.isDialog = !this.isDialog
      } else {
        return this.$message({
          type: "warning",
          message: "公司还没有提交反馈！",
        });
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.internship {
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /deep/ .custom-label-form-item .el-form-item__label {
    font-size: 19px;
    font-weight: bold;
  }
  .feedback {
    color: #ff6464;
    cursor: pointer;
    border-bottom: 1px solid;
  }
}
</style>